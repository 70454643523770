// .project-card {
//     width: 100%;
//     border: 1px solid #ccc;
//     padding: 20px;
//     margin: 10px;
//     box-shadow: 0 0 5px #ccc;
//   }

//   .project-card h2 {
//     font-size: 24px;
//     margin: 0 0 10px;
//   }

//   .project-card p {
//     font-size: 16px;
//     margin: 0 0 5px;
//   }

//   .project-card a {
//     display: block;
//     margin: 10px 0;
//   }

//   .project-card img {
//     width: 100%;
//     height: 400px;
//     border: none;
//   }

.project {
    padding: 3em 0;
    position: relative;

    .heading {
        font-weight: 800;
        font-size: 30px;
        margin-bottom: 30px;
        color: $color-primary;
    }

    .project-card {
        width: 50%;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        transition: all 0.3s ease;
        background-color: $primary-color-light;
        transition: background-color 0.5s ease-in-out;

        .project-title {
            background-color: $card-header-color;
            transition: background-color 0.5s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
        }

        .project-header {
            height: auto;
            overflow: hidden;
            margin: 10px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }


        .project-details {
            padding: 20px;

            p {
                font-size: 16px;
                margin: 0 0 5px;

                span{
                    font-weight: bold;
                }
            }

            a {
                display: block;
                margin: 10px 0;
                padding: 10px 20px;
                color: $primary-color-light;
                background-color: $button-color-primary;
                border-radius: 5px;
                text-align: center;
                text-decoration: none;
                transition: all 0.3s ease;
            }

            a:hover {
                background-color: $button-color-secondary;
            }

            a:active {
                transform: translateY(2px);
            }
        }
    }

    .project-card:hover {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
    }

}

#dark .project-card {
    background-color: #222222;

    .project-title {
        background-color: #333333;
    }
}

//Mobile
@media (max-width: 600px) {
    .project-card {
        width: 100% !important;
    }
}

//Tablet
@media (max-width: 767.98px) {
    .project-card {
        width: 100% !important;
    }
}