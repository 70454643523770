.contact {
    .contact-form {
        width: 100%;
        height: 90vh;
        margin: auto;
        position: relative;
    }

    .form-control {
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
        padding: 12px 20px;
        margin-bottom: 20px;
        width: 100%;
    }

    .form-control:focus {
        border: 2px solid $color-primary;
        outline: none;
    }

    .img-about {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        margin: 20px;
        text-align: center;
        z-index: 0;
        position: relative;
        overflow: hidden;

        .img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            position: relative;
            z-index: 1;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}

//Mobile
@media (max-width: 600px) {
    .contact-left {
       height: 50vh !important;
    }
}

//Tablet
@media (max-width: 767.98px) {
    .contact-left {
        height: 50vh !important;
     }
}