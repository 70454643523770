.about {
    .about-container {
        .about-left {
            padding: 20px;
            height: 90vh;

            .img-about {
                width: 100%;
                border-radius: 10px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                margin: 20px;
                text-align: center;
                z-index: 0;
                position: relative;
                overflow: hidden;

                .img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    position: relative;
                    z-index: 1;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }

        .about-right {
            padding: 20px;
            height: 90vh;
        }
    }
}

#dark .img-about {
    background-color: #333333;
}

.my-about-section {
    position: relative;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.d-flex {
    display: flex !important;
}

.heading-section {
    position: relative;
    margin: auto;

    h2 {
        font-size: 50px;
        font-weight: 700;
        color: $color-primary;
        span {
            font-weight: 400;
        }
    }
}

//Mobile
@media (max-width: 600px) {
    .about {
        .about-container {
            .about-left {
                height: 50vh;
                .img-about {
                    border-radius: 70%;
                    padding: 0;
                    background-color: black;

                    .img {
                        border-radius: 70px;
                    }
                }
            }
        }
    }
}

//Tablet
@media (max-width: 767.98px) {
    .heading-section h2 {
        font-size: 38px;
    }
}

.about-para {
    margin-inline-start: 10px;
}

.about-info {
    display: inline-block;
    text-align: left;

    >li {
        list-style: none;
        margin-bottom: 10px;
        margin-inline-start: 10px;

        >span {
            width: calc(100% - 130px);

            &:first-child {
                font-weight: 600;
                width: 130px;
            }
        }
    }
}

.about-email {
    color: $secondary-color-light !important;
    transition: color 0.5s ease-in-out;
}

#dark .about-email {
    color: $secondary-color-dark !important;
}

.counter-wrap {
    display: flex;
    justify-content: center;
}