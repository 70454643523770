.resume {
    padding: 7em 0;
    position: relative;
}

//Tablet
@media (max-width: 767.98px) {
    .resume {
        padding: 6em 0;
    }
}

.left-col {
    position: relative;
    margin-bottom: calc(100vh - 200px);
}

.right-col {
    overflow-y: auto;
}

#navi {
    top: 180px;
    position: sticky;
    margin: 0;

    ul {
        margin: 0;
        padding: 0;

        li {
            font-size: x-large;
            font-weight: 700;
            list-style: none;
            margin-bottom: 10px;
        }
    }
}

#dark .resume-link {
    color: $secondary-color-dark;
}

.resume-link {
    margin-left: 20px;
    position: relative;
}

.disable {
    color: $secondary-color-light;
}

.resume-link:after {
    position: absolute;
    top: 50%;
    left: -24px;
    width: 20px;
    height: 2px;
    content: '';
    transform: translateY(-50%);
    background: $color-primary;
}

.page {
    width: 100%;
    margin-bottom: 7em;

    .heading {
        font-weight: 800;
        font-size: 30px;
        margin-bottom: 30px;
        color: $color-primary;
    }
}

.page.four {
    margin-bottom: 0;
}

.skill-card {
    background-color: $primary-color-light;
    border-radius: 2rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    width: 100%;
    overflow: hidden;

    .skill-title {
        background-color: $card-header-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .technology {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;


        .technology-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .content-text {
                font-weight: bold;
                margin-top: 10px;
            }
        }
    }
}

#dark .skill-card {
    background-color: #222222;

    .skill-title {
        background-color: #333333;
    }
}

.resume-card {
    background-color: $primary-color-light;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 2rem;
    overflow: hidden;
    width: 100%;
    margin-bottom: 30px;

    .card-header {
        background-color: $card-header-color;
        display: flex;
        align-items: center;
        padding: 10px;
    }

    .icon {
        background-color: $color-primary;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: $primary-color-light;
            font-size: 28px;
        }

        span:hover {
            color: $secondary-color-light;
            font-size: 30px;
        }
    }


    .header-text {
        width: calc(100% - 50px);
        margin-left: 10px;

        h2 {
            margin: 0;
            font-size: 24px;
            font-weight: bold;
        }

        .date {
            font-weight: 700;
            font-size: 20px;
            color: $color-primary;
        }

        .gpa,
        .position {
            font-size: 18px;
            font-weight: 700;
            color: #777;
        }
    }

    .card-body {
        padding: 30px;

        ul {
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 10px;
                font-size: 20px;
                line-height: 1.5;
            }
        }
    }
}

#dark .resume-card {
    background-color: #222222;

    .card-header {
        background-color: #333333;
    }

    .position {
        color: #bdbcbc;
    }
}

.certification-card {
    background-color: $primary-color-light;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 2rem;
    text-align: center;
    width: 100%;
    overflow: hidden;

    .certification-title {
        background-color: $card-header-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .certification-content {
        img {
            border-radius: 50%;
            margin-bottom: 20px;
            width: 150px;
        }

        h2 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        p {
            span {
                font-weight: bold;
            }

            font-size: 16px;
            margin-bottom: 5px;
        }

        a {
            border: none;
            border-radius: 4px;
            display: inline-block;
            font-size: 16px;
            margin-top: 20px;
            padding: 10px 20px;
            text-decoration: none;
            transition: background-color 0.2s;
        }
    }

}

#dark .certification-card {
    background-color: #222222;

    .certification-title {
        background-color: #333333;
    }
}