.btn {
    cursor: pointer;
    border-radius: 40px;
    box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

.btn-primary {
    color: $primary-color-light;
    background-color: $button-color-primary;
    border-color: $secondary-color-light;
    &:hover {
        background-color: $button-color-secondary !important;
    }
}

