.my-section {
    padding: 7em 0;
    position: relative;
    bottom: 0;
}

@media (max-width: 767.98px) {
    .my-section {
        padding: 6em 0;
    }
}

.my-footer {
    font-size: 16px;
    background: $foot-background-light;
    padding: 7em 0;
    z-index: 0;
    color: $foot-text-light;
    text-align: left;

    & p {
        color: $foot-para-light;
    }

    & a {
        color: $foot-para-light;
    }

    & a:hover {
        color: $secondary-color-light;
    }    
}

.my-footer-widget {
    >h2 {
        font-weight: normal;
        margin-bottom: 40px;
        font-size: 22px;
        font-weight: 600;

    }

    >ul li {
        margin-bottom: 10px;

        >a {
            color: $foot-para-light;
        }

    }
}

.my-footer {
    a:hover {
        color: $primary-color-light;
    }
}

#dark.my-footer {
    background: $foot-background-dark;
}

.list-icon-social {
    & li {
        list-style: none;
        margin: 0 10px 0 0;
        display: inline-block;

        & a {
            height: 50px;
            width: 50px;
            display: block;
            float: left;
            background: #3b3e47;
            border-radius: 50%;
            position: relative;

            & .twitter-icon {
                color: $twitter-icon;
            }

            & .facebook-icon {
                color: $facebook-icon;
            }

            & .linkedin-icon {
                color: $linkedin-icon;
            }

            & .instagram-icon {
                color: $instagram-icon;
            }

            & span {
                position: absolute;
                font-size: 30px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            & :hover {
                color: $primary-color-light;
            }
        }
    }
}

.favourite-icon {
    color: $favourite-icon;
}

.footer-heading-2 {
    font-size: 17px;
    font-weight: 400;
    color: $primary-color-light;
}

a:link {
    text-decoration: none;
}

.block-23 ul {
    padding: 0;
}

.block-23 ul li,
.block-23 ul li>a {
    display: table;
    line-height: 1.5;
    margin-bottom: 15px;
}

.block-23 ul li span {
    color: $foot-para-light;
}

.block-23 ul li .icon,
.block-23 ul li .text {
    display: table-cell;
    vertical-align: top;
}

.block-23 ul li .icon {
    width: 40px;
    font-size: 18px;
    padding-top: 2px;
    color: $primary-color-light;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}