
p {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    color: $secondary-color-light;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
    line-height: 1.5;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.5rem;
}