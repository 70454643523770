.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}


.px-2 {
    padding-right: 0.5rem !important;
}


.px-2 {
    padding-left: 0.5rem !important;
}


.py-3 {
    padding-top: 1rem !important;
}


.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}


.px-4 {
    padding-right: 1.5rem !important;
}


.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}


.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}


.px-5 {
    padding-left: 3rem !important;
}


.mx-auto {
    margin-right: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 768px) {
    .mt-md-3 {
        margin-top: 1rem !important;
    }

    .ml-md-4 {
        margin-left: 1.5rem !important;
    }

    .px-md-0 {
        padding-right: 0 !important;
    }

    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }
}

.text-center {
    text-align: center !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.visible {
    visibility: visible !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-primary {
    border-color: $color-primary !important;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    >.col,
    >[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

.float-right {
    float: right !important;
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }
}

.position-static {
    position: static !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}