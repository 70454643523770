.knowledge {
    padding: 4em 0 !important;
    padding: 7em 0;
    position: relative;
}

.knowledge .partner {
    display: block;
    padding: 0 20px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

@media (max-width: 767.98px) {
    .knowledge {
        padding: 6em 0;
    }
}

@media (max-width: 991.98px) {
    .knowledge .partner {
        padding: 0 70px;
        margin-bottom: 40px;
    }
}