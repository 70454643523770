.hero-wrap {
    position: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.hero-wrap .text {
    width: 100%;
}

.slider-text {
    .subheading {
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 800;
        color: $text-color-light;
        letter-spacing: 4px;
    }

    h1 {
        font-size: 60px;
        font-weight: 800;
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }

    h2 {
        font-weight: 800;
        font-size: 2rem;

        span {
            color: $text-color-light;
            text-decoration: underline;
            
        }
    }
}


@media (max-width: 767.98px) {
    .slider-text h1 {
        font-size: 40px;
    }
}

