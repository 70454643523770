// Global app reset
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
}

main,
footer,
header,
section {
  display: block;
}

body {
  margin: 0;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}