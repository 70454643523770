$color-white: #fff;
$color-black: #000000;
$color-red: #ed4956;

// $color-grey-1: #999;
// $color-grey-2: #dbdbdb;
// $color-grey-3: #fafafa;
// $color-grey-4: #efefef;

$color-grey: rgba(255, 255, 255, 0.7);
$navbar-menu-light:rgba(255, 255, 255, 0.7);
$navbar-menu-dark: rgba(70, 70, 70, 0.7); 
$color-primary: #3e64ff;
$color-secondary: #0A1535;

$icon-huge-size: 15rem;
$icon-larger-size: 6rem;
$icon-large-size: 4rem;
$icon-medium-size: 2.7rem;
$icon-small-size: 2rem;
$icon-tiny-size: 1.3rem;

$card-header-color: #eee;

$footer-height: 10rem;
$foot-background-light: $color-secondary;
$foot-text-light: $color-grey;
$foot-para-light: #999;

$foot-background-dark: #111111;
$foot-text-dark: rgba(255, 255, 255, 0.7);
$foot-para-dark: #999;

$twitter-icon: #1DA1F2;
$facebook-icon: #4267B2;
$linkedin-icon: #0A66C2;
$instagram-icon: #C13584;
$favourite-icon: #E50914;


$primary-color-light: #ffffff;
$secondary-color-light: #111111;
$background-color-light: #d5defd;
$text-color-light: $color-primary;
$button-color-light: $color-primary;
$progress-bar-light: $color-primary;
$navbar-light: $color-primary;

$primary-color-dark: #111111;
$secondary-color-dark: #ffffff;
$background-color-dark: #111111;
$text-color-dark: $color-primary;
$button-color-dark: $color-primary;
$progress-bar-dark: $color-primary;
$navbar-dark:$color-primary;

$button-color-primary: #3e64ff;
$button-color-secondary: #0056b3;